import React from "react";
import styled, { keyframes } from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";

// Fast shake animation
const shake = keyframes`
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
`;

const Li = styled.li`
  margin: 0;
  padding: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px;
  box-sizing: border-box;

  &:nth-child(8n) {
    grid-column-end: span 2;
    grid-row-end: span 2;
  }

  h3,
  p {
    margin: 0;
    padding: 0;
  }

  .morf-info {
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
    display: flex;
    flex-direction: column;
  }

  .morf-img {
    position: relative;
    z-index: 1;
    overflow: hidden;
    border-radius: 10px;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;

    h3 {
      position: absolute;
      left: 10px;
      top: -25px;
      z-index: 10;
      color: #fff;
      font-weight: 400;
      font-size: 1.2rem;
      text-shadow: 2px 2px 0px #222;
      transition: top 0.2s ease-in-out;
      white-space: nowrap;

      @media (max-width: 768px) {
        font-size: 1rem;
      }
    }

    .gatsby-image-wrapper {
      width: 100%;
      height: 100%;
      transition: transform 0.2s ease-in-out;

      img {
        max-width: 100%;
        transition: transform 0.2s ease-in-out;
      }
    }
  }

  &:hover {
    cursor: pointer;

    img {
      transform: scale(1.1);
    }

    .morf-img {
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 1);
      transform: scale(1.1);
      animation: ${shake} 0.5s ease-in-out infinite;

      h3 {
        top: 10px;
      }
    }
  }

  &.open {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;

    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.7);
    margin: 0;
    cursor: default;

    .morf-img {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      animation: none;
      transform: scale(1) !important;

      h3 {
        top: 0;
        left: 0;
        right: 0;
        padding: 10px 0;
        width: 100%;
        text-align: center;
        font-size: 2rem;
        pointer-events: none;
      }

      button {
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 100;
        background-color: transparent;
        border: none;
        color: #fff;
        font-size: 2rem;
        cursor: pointer;
        transition: all 0.3s ease-in-out;

        &:hover {
          color: #ff0000;
        }
      }

      .gatsby-image-wrapper {
        max-width: 880px;
        max-height: 880px;

        height: 100%;

        img {
          transform: scale(1) !important;
        }
      }
    }

    &:hover {
      img {
        transform: scale(1);
      }
    }
  }
`;

function Gallery({ item }) {
  const [clicked, setClicked] = React.useState(false);
  const { contentfulid: id, image, fullImage, name } = item;

  const onClick = () => {
    setClicked(!clicked);
  };

  React.useEffect(() => {
    const handleEsc = (e) => {
      if (e.keyCode === 27) {
        setClicked(false);
      }
    };

    window.addEventListener("keydown", handleEsc);

    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, []);

  return (
    <Li
      id={id}
      key={id}
      onClick={clicked ? null : onClick}
      onKeyPress={clicked ? null : onClick}
      className={clicked ? "open" : ""}
      tabIndex={0}
    >
      <div className="morf-img">
        {clicked && <button onClick={onClick}>X</button>}
        <h3>{name}</h3>
        <GatsbyImage
          image={clicked ? fullImage.gatsbyImageData : image.gatsbyImageData}
          alt={name}
        />
      </div>
    </Li>
  );
}

export default Gallery;
