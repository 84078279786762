import React, { useEffect, useRef, useState } from "react";
import "../styles/index.css";
import styled from "styled-components";
import { Helmet } from "react-helmet";

// import graphql
import { graphql } from "gatsby";

// Components
import Gallery from "../components/Gallery";
import Stats from "../components/Stats";

const ViewButton = styled.button`
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  color: #fff;
  font-family: "Nunito", sans-serif;
  font-size: 1.2rem;
  font-weight: 700;
  cursor: pointer;
  text-shadow: 2px 2px 0px #222;

  transition: all 0.3s ease-in-out;
  position: relative;

  @media (max-width: 768px) {
    width: 100%;
  }

  &:after {
    content: "";
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #fff;
    opacity: 0;
    transition: all 0.3s ease-in-out;
  }

  &:hover {
    &:after {
      opacity: 1;
      bottom: -15px;
    }
  }

  @media (max-width: 768px) {
    margin-top: 20px;
  }
`;

const Hero = styled.div.attrs({
  id: "hero"
})`
  overflow: hidden;
  box-sizing: border-box;

  .logo {
    width: 600px;
    max-width: 100%;

    img {
      max-width: 1200px;
      max-height: 500px;
      width: 100%;
      height: auto;
    }
  }
`;

const JoepegsLink = styled.a`
  height: 50px;

  position: fixed;
  left: 40px;
  bottom: 25px;
  z-index: 100;

  display: flex;
  justify-content: center;
  align-items: center;

  font-family: "Nunito", sans-serif;
  font-size: 1rem;
  color: #fff;
  text-decoration: none;
  transition: all 0.3s ease-in-out;

  &:hover {
    text-shadow: 2px 2px 0px #222;
  }

  img {
    width: 40px;
    height: auto;
    margin-right: 10px;
  }

  transition: all 0.3s ease-in-out;
`;

const Footer = styled.footer`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 200px 0 100px;

  img {
    width: 100px;
    height: auto;
  }
`;

const Head = () => (
  <Helmet
    htmlAttributes={{
      lang: "en"
    }}
  >
    <title>Morfs</title>
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <meta
      name="description"
      content="Morfs NFT - Creatures of clay come out to play"
    />
    <meta name="language" content="en" />
    <meta name="robots" content="index, follow" />
    {/* favicon */}
    <link rel="icon" type="image/png" href="/favicon.ico" />
    {/* manifest */}
    <link rel="manifest" href="/site.webmanifest" />
  </Helmet>
);

function Index({ data }) {
  const ref = useRef();
  const [hasScrolled, setHasScrolled] = useState(false);
  const {
    allContentfulMorf: { nodes: items }
  } = data;

  // on click, scroll height of Hero
  const onClickHandler = () => {
    console.log(ref);
    if (!ref.current) return;

    ref.current.scrollIntoView({
      behavior: "smooth",
      block: "start"
    });
  };

  const onScrollHandler = () => {
    if (window.scrollY > 1) {
      setHasScrolled(true);
    } else {
      setHasScrolled(false);
    }
  };

  const onResizeHandler = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  };

  useEffect(() => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
    // resize
    window.addEventListener("resize", () => onResizeHandler);

    window.addEventListener("scroll", onScrollHandler);
    return () => {
      window.removeEventListener("scroll", onScrollHandler);
      window.removeEventListener("resize", onResizeHandler);
    };
  }, []);

  return (
    <>
      <Head />
      <main>
        <Hero>
          <div className="logo">
            <img src="/logo.png" width={600} height={250} alt="logo" />
          </div>

          <img
            src="/morf.png"
            className={hasScrolled ? "morf scrolled" : "morf not-scrolled"}
            alt=""
          />

          <Stats>
            <ViewButton onClick={onClickHandler}>View Collection</ViewButton>
          </Stats>

          <JoepegsLink
            href="https://joepegs.com/collections/morfs"
            target="_blank"
            className={hasScrolled ? "scrolled" : "not-scrolled"}
          >
            <img src="/joepegs-logo.png" alt="morfs" />
            Buy on Joepegs
          </JoepegsLink>
        </Hero>
        <Gallery ref={ref} items={items} />

        <Footer>
          <img src="/morfs_face.png" alt="morfs face" />
        </Footer>
      </main>
    </>
  );
}

export const pageQuery = graphql`
  query MyQuery {
    allContentfulMorf {
      nodes {
        name
        contentfulid
        image {
          gatsbyImageData(
            layout: CONSTRAINED
            width: 580
            quality: 100
            formats: [AUTO, WEBP]
          )
        }
        fullImage: image {
          gatsbyImageData(
            layout: CONSTRAINED
            width: 880
            quality: 100
            formats: [AUTO, WEBP]
          )
        }
      }
    }
  }
`;

export default Index;
