import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import axios from "axios";

const FadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const StatsContainer = styled.div`
  position: absolute;
  right: 0;
  bottom: 40px;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 100;
  box-sizing: border-box;

  animation: ${FadeIn} 0.5s ease-in-out forwards;

  @media (max-width: 768px) {
    align-items: flex-start;
    bottom: 100px;
    padding: 0 40px;
  }

  ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 20px;

    li {
      display: flex;
      justify-content: center;
      flex-direction: column;

      font-size: 1.5rem;
      font-weight: 600;
      font-family: sans-serif;
      padding: 40px;

      @media (max-width: 768px) {
        padding: 10px 0;
        flex: 0 0 100%;
      }

      span {
        display: flex;
        align-items: center;
        color: #fff;

        font-size: 2rem;
        font-weight: 700;
        // text shadow
        text-shadow: 3px 3px 0px #222;

        @media (max-width: 768px) {
          font-size: 1.5rem;
        }

        &:first-child {
          font-weight: 400;
          font-size: 1rem;
          margin-bottom: 10px;
          text-shadow: 2px 2px 0px #222;
        }

        img {
          margin-left: 6px;
          box-shadow: 1px 1px 0px #222;
          border-radius: 50%;
        }
      }
    }
  }
`;

function Stats({ children }) {
  const [data, setData] = useState(null);

  // useEffect get new data from api/items endpoint
  useEffect(() => {
    async function getData() {
      const url = `${window.location.origin}/api/collection`;

      const res = await axios.get(url, {
        method: "GET"
      });
      // 404 from axios
      if (res.status === 404) {
        return;
      }
      const { data } = res;
      setData(data);
    }
    getData();
  }, []);

  return (
    <StatsContainer>
      <ul>
        <li>
          <span>Morfs</span>
          <span>{data ? data.size : "100"}</span>
        </li>
        <li>
          <span>Holders</span>
          <span>{data ? data.owners : "-"}</span>
        </li>
        <li>
          <span>Floor</span>
          <span>
            {data ? data.floor.toFixed(2) : "-"}
            <img src="/avax.svg" alt="avax" width={20} height={20} />
          </span>
        </li>
        <li>
          <span>Volume</span>
          <span>
            {data ? data.volume.toFixed(2) : "-"}
            <img src="/avax.svg" alt="avax" width={20} height={20} />
          </span>
        </li>
      </ul>
      {children}
    </StatsContainer>
  );
}

export default Stats;
