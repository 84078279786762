import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";

// Components
import Item from "./gallery/Item";

const Section = styled.section`
  background: rgb(0, 164, 255);
  margin-top: 40px;
`;

const Ul = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0 auto;
  max-width: 1200px;

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(25%, 1fr));
  grid-auto-rows: auto;
  grid-auto-flow: dense;

  // media query max width 768px
  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fit, minmax(33.333%, 1fr));
  }
`;

function Gallery({ forwardedRef, items }) {
  const [data, setData] = useState(null);
  const [sortedItems, setSortedItems] = useState(items);

  const randomSort = (a, b) => {
    return 0.5 - Math.random();
  };

  useEffect(() => {
    const sortItems = items.sort(randomSort);
    setSortedItems(sortItems);

    async function getData() {
      const url = `${window.location.origin}/api/items`;

      const res = await axios.get(url, {
        method: "GET"
      });
      const { data } = res;
      setData(data);
    }
    getData();
  }, [items]);

  return (
    <>
      <Section ref={forwardedRef}>
        <Ul>
          {sortedItems.map((item) => {
            const { contentfulid: id } = item;
            const itemData = data?.find((item) => item.id === id);
            return <Item key={id} id={id} item={item} itemData={itemData} />;
          })}
        </Ul>
      </Section>
    </>
  );
}

export default React.forwardRef((props, ref) => (
  <Gallery {...props} forwardedRef={ref} />
));
